<template>
  <!-- Error page-->
  <div class="warning-wrapper">
    <b-card class="warning-card">
      <b-row>
        <b-col cols="auto">
          <feather-icon
            icon="AlertCircleIcon"
            style="color: orange"
            width="1.6vw"
            height="1.6vw"
          />
        </b-col>
        <b-col>
          <h4 v-show="contextData.length > 0" class="warning-text">
            {{ $t("No record found in selected date range") }}:
            {{ $t(contextData) }}
          </h4>
          <h4 v-show="contextData.length == 0" class="warning-text">
            {{ $t("No record found in selected date range") }}
          </h4>
        </b-col>
      </b-row>
    </b-card>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BCard, BCol, BRow } from "bootstrap-vue";

import { computed, toRefs } from "@vue/composition-api";
import store from "@/store";
export default {
  components: {
    BCard,
    BRow,
    BCol,
  },
  props: {
    context: "",
  },
  setup(props) {
    const { context } = toRefs(props);

    const dateFilter = computed(() => {
      const dateFilter = store.getters["pamis/GetDateFilter"];
      if (dateFilter) return dateFilter.text.toLowerCase();
      return "";
    });
    const contextData = computed(() => {
      if (typeof context.value == "string") return context.value.toLowerCase();
      return "";
    });
    return {
      dateFilter,
      contextData,
    };
  },
};
</script>

<style scoped>
.warning-card {
  border-color: orange;
  background-color: rgba(255, 145, 0, 0.2);
}

.warning-text {
  padding-top: 0.3vw;
  color: orange;
  text-decoration: orange;
}
</style>
