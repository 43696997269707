<template>
  <div>
    <b-row style="margin-bottom: 15px">
      <b-col>
        <b-form>
          <b-row>
            <b-col cols="2">
              <b-form-group
                style="margin-top: -10px; margin-bottom: 0px"
                label-for="example-datepicker"
                label="Start Date"
              >
                <flat-pickr
                  v-model="startDate"
                  id="startDate"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    locale: $i18n.t('locale_datePickr'),
                    dateFormat: 'Y-m-d H:i',
                    minuteIncrement: 1,
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group
                style="margin-top: -10px; margin-bottom: 0px"
                label-for="example-datepicker"
                label="End Date"
              >
                <flat-pickr
                  v-model="endDate"
                  id="endDate"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    locale: $i18n.t('locale_datePickr'),
                    dateFormat: 'Y-m-d H:i',
                    minuteIncrement: 1,
                    minDate: 'today',
                  }"
                ></flat-pickr>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-button
                variant="success"
                style="margin-top: 11px"
                @click="searchDataInRange"
              >
                Search
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
//emit functions will be implemented.
import flatPickr from "vue-flatpickr-component";
import {
  BButton,
  BCardBody,
  BCardFooter,
  BCardHeader,
  BCard,
  BCardTitle,
  BRow,
  BCol,
  BForm,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BTable,
  BBadge,
} from "bootstrap-vue";
import store from "@/store";
export default {
  name: "DateFilter",
  components: {
    BButton,
    BCardBody,
    BCardFooter,
    BCardHeader,
    BCard,
    BCardTitle,
    BCol,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    flatPickr,
    BTable,
    BBadge,
  },
  data() {
    return {
      isAdvanced: false,
      selectedType: 1,
      startDate: new Date(),
      endDate: new Date(),
    };
  },
  methods: {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
