import { computed } from "@vue/composition-api";
import store from "@/store";
import { formatDate } from "@core/utils/filter";
import { GetGroupStatus, GetDataStatus } from "@/utils/utils.js";
import i18n from '@/libs/i18n'

export default function useBreakdownGroup() {
  const mesDowntimeList = computed(() => {
    const data = store.getters["pamis-workcenter-module/GetMESBreakdownInfo"];
    const dataState = GetDataStatus(data);
    if (dataState.response_status == "success") {
      const options = {
        response_status: "success",
        isClick: false,
        header: i18n.t('Breakdowns'),
        widgetType: "TableBootstrap",
        items: data.datasource,
        fields: [
          {
            key: "start",
            label: i18n.t('Start Date'),
            formatter: (value, key, item) => {
              return formatDate(value, {
                month: "numeric",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              });
            },
          },
          {
            key: "end",
            label: i18n.t('End Date'),
            formatter: (value, key, item) => {
              return formatDate(value, {
                month: "numeric",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              });
            },
          },
          {
            key: "duration",
            label: i18n.t('Duration'),
            formatter: (value, key, item) => {
              return value + " min";
            },
          },
          { key: "fault", label: i18n.t('Fault') },
        ],
      };
      return options;
    } else return dataState;
  });
  const actualDowntimeList = computed(() => {
    const data =
      store.getters["pamis-workcenter-module/GetBreakdownActualInfo"];
    const dataState = GetDataStatus(data);
    if (dataState.response_status == "success") {
      const options = {
        response_status: "success",
        isClick: false,
        header: "Breakdowns (Actual)",
        widgetType: "TableBootstrap",
        items: data.datasource,
        fields: [
          {
            key: "start",
            formatter: (value, key, item) => {
              return formatDate(value, {
                month: "numeric",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              });
            },
          },
          {
            key: "end",
            formatter: (value, key, item) => {
              return formatDate(value, {
                month: "numeric",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              });
            },
          },
          {
            key: "duration",
            formatter: (value, key, item) => {
              return value + " min";
            },
          },
          { key: "fault" },
        ],
      };
      return options;
    } else {
      return dataState;
    }
  });
  const mttrChart = computed(() => {
    let data =
      store.getters["pamis-workcenter-module/GetWorkcenterSummaryInfo"];
    const dataState = GetDataStatus(data);
    if (dataState.response_status == "success") {
      let mtData = data.datasource;
      let mttrData = mtData.filter((e) => e.name == "Mttr")[0];
      let mtbfData = mtData.filter((e) => e.name == "Mtbf")[0];
      let options = {
        response_status: "success",
        widgetType: "HighchartColumn",
        title: {
          text: "MTTR / MTBF (Min)",
        },
        legend: {
          enabled: false,
        },
        yAxis: {
          title: { text: i18n.t('Minutes') },
        },
        xAxis: {
          type: "category",
          categories: ["MTTR", "MTBF"],
        },
        series: [
          {
            name: "",
            data: [
              {
                name: "MTTR",
                color: "#AAAAAA",
                y: mttrData?.value,
              },
              {
                name: "MTBF",
                color: "#202081",
                y: mtbfData?.value,
              },
            ],
          },
        ],
      };
      return options;
    } else {
      return dataState;
    }
  });
  const breakdownRateChart = computed(() => {
    let data = store.getters["pamis-workcenter-module/GetBreakdownRate"];

    const dataState = GetDataStatus(data);
    if (dataState.response_status == "success") {
      let options = {
        response_status: "success",
        widgetType: "HighchartColumn",
        title: {
          text: i18n.t('Breakdown Rate'),
        },
        legend: {
          enabled: false,
        },
        yAxis: {
          title: { text: i18n.t('Percentile') },
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: "%{y}",
            },
          },
        },
        xAxis: { type: "category", categories: [`${i18n.t('Breakdown Rate')}`] },
        series: [
          {
            name: "",
            data: [
              {
                name: `${i18n.t('Breakdown Rate')}`,
                y: data.datasource.value,
              },
            ],
          },
        ],
      };
      return options;
    } else {
      return dataState;
    }
  });



  const checkGroupStatus = computed(() => {
    const options = [
      mesDowntimeList.value.response_status,
      actualDowntimeList.value.response_status,
      mttrChart.value.response_status,
      breakdownRateChart.value.response_status,
    ];
    return GetGroupStatus(options);
  });
  return {
    breakdownRateChart,
    mesDowntimeList,
    actualDowntimeList,
    mttrChart,
    checkGroupStatus,
  };
}
